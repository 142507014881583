<template>
	<v-card class="mt-2 mb-5 pb-2" rounded="xl">
		<v-card-title>
			<span>{{ $t('offers.activity') }}</span>
		</v-card-title>
		<v-simple-table fixed-header>
			<table width="100%" cellspacing="0">
				<thead>
					<tr>
						<th class="text-center align-middle">{{ $t('offers.change') }}</th>
						<th class="text-center align-middle">{{ $t('offers.date') }}</th>
						<th class="text-center align-middle">{{ $t('applications.status') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center">
							{{ $t('events.eventCreated') }}
						</td>
						<td class="text-center">{{ event ? humanTime(event.timestamp) : '' }}</td>
						<td class="text-center">
							<v-chip v-if="!event.status.verified.value" small :ripple="false" color="red" class="my-1">
								<v-icon left>mdi-close</v-icon> {{ $t('users.verified') }}
							</v-chip>
						</td>
					</tr>
					<tr v-for="activity in event.activity" :key="activity.id * activity.id">
						<template v-if="activity.title">
							<td class="text-center">
								{{ $t('offers.change') }} #{{ activity.index }}
								<EventChange :loading-parent="loading || loadingParent" :change="activity" />
							</td>
							<td class="text-center">{{ humanTime(activity.timestamp) }}</td>
							<td class="text-center">
								<template v-if="!activity.status.pending">
									<v-chip small :ripple="false" :color="statusColor(activity.status)" class="my-1" :outlined="activity.status.canceled">
										<v-icon left>{{ statusIcon(activity.status) }}</v-icon>
										{{ statusText(activity.status) }}
									</v-chip>
								</template>
								<template v-else>
									<v-btn color="green darken-3" small rounded class="mx-2" @click="callApproveChange(activity)">
										<v-icon left>mdi-check</v-icon> {{ $t('offers.approve') }}
									</v-btn>
									<v-btn color="orange darken-3" small rounded class="mx-2" @click="callRefuseChange(activity)">
										<v-icon left>mdi-close</v-icon> {{ $t('offers.refuse') }}
									</v-btn>
									<v-btn color="orange darken-3" small rounded class="mx-2" @click="callCancelChange(activity)">
										<v-icon left>mdi-close</v-icon> {{ $t('offers.cancel') }}
									</v-btn>
								</template>
							</td>
						</template>
						<template v-else>
							<td class="text-center">
								{{ activity.name }}
							</td>
							<td class="text-center">{{ humanTime(activity.timestamp) }}</td>
							<td class="text-center">
								<template v-if="activity.type == 'event_verified_1'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('users.verified') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_verified_0'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('users.unverified') }} </v-chip>
								</template>
								<template v-if="activity.type == 'event_visible_1'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('users.visible') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_visible_0'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('users.notVisible') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_active'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('offers.active') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_canceled'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('offers.canceled') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_finished'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('events.finished') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_closed'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('offers.closed') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'event_opened'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('offers.opened') }} </v-chip>
								</template>
							</td>
						</template>
					</tr>
				</tbody>
			</table>
		</v-simple-table>

		<v-card-actions class="d-flex flex-wrap">
			<v-btn v-if="!event.status.verified.value" color="green" rounded :loading="loading || loadingParent" @click="callVerifyEvent()">
				{{ $t('auth.verify') }}
			</v-btn>
			<v-btn
				v-if="!event.status.canceled.value && !event.status.finished.value"
				color="green"
				rounded
				:loading="loading || loadingParent"
				@click="callFinishEvent()"
			>
				{{ $t('events.finish') }}
			</v-btn>
			<v-btn v-if="!event.status.active.value" color="green" rounded :loading="loading || loadingParent" @click="callActivateEvent()">
				{{ $t('offers.activate') }}
			</v-btn>
			<v-btn
				v-if="!event.status.canceled.value && !event.status.finished.value"
				color="red"
				rounded
				:loading="loading || loadingParent"
				@click="callCancelEvent()"
			>
				{{ $t('offers.cancel') }}
			</v-btn>
			<template v-if="event.status.closed.value">
				<v-btn color="green" rounded :loading="loading || loadingParent" @click="callOpenEvent()">
					{{ $t('offers.open') }}
				</v-btn>
			</template>
			<template v-else>
				<v-btn color="red" rounded :loading="loading || loadingParent" @click="callCloseEvent()">
					{{ $t('search.close') }}
				</v-btn>
			</template>
			<v-checkbox v-model="alertAttendances" :label="$t('offers.alert')" outlined />
			<v-btn color="primary" rounded :to="{ name: 'EventCreate', query: { reuse: true } }">
				{{ $t('offers.reuseAsTemplate') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'EventActivity',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			alertAttendances: false
		}
	},
	components: {
		EventChange: () => import('@/components/events/EventChange.vue')
	},
	computed: {
		...mapGetters({
			event: 'events/event'
		})
	},
	created() {},
	methods: {
		statusColor(status) {
			if (status.refused | status.canceled) return 'red'
			else if (status.approved) return 'green'
			else return 'grey'
		},
		statusIcon(status) {
			if (status.refused | status.canceled) return 'mdi-close'
			else if (status.approved) return 'mdi-check'
			else return 'mdi-help-circle-outline'
		},
		statusText(status) {
			if (status.refused) return this.$t('offers.refused')
			else if (status.canceled) return this.$t('offers.canceled')
			else if (status.approved) return this.$t('offers.approved')
			else return this.$t('offers.pending')
		},
		humanTime(timestamp) {
			return this.$moment(timestamp).format('YYYY-MM-DD hh:mm:ss')
		},
		callCancelChange(change) {
			this.loading = true
			this.cancelChange(change.id).then(() => {
				this.loading = false
			})
		},
		callApproveChange(change) {
			this.loading = true
			this.approveChange({ changeID: change.id, alertAttendances: this.alertAttendances }).then(() => {
				this.loading = false
			})
		},
		callRefuseChange(change) {
			this.loading = true
			this.refuseChange(change.id).then(() => {
				this.loading = false
			})
		},
		callVerifyEvent() {
			this.loading = true
			this.verifyEvent(this.event.id).then(() => {
				this.loading = false
			})
		},
		callFinishEvent() {
			this.loading = true
			this.finishEvent(this.event.id).then(() => {
				this.loading = false
			})
		},
		callActivateEvent() {
			this.loading = true
			this.activateEvent(this.event.id).then(() => {
				this.loading = false
			})
		},
		callCancelEvent() {
			this.loading = true
			this.cancelEvent(this.event.id).then(() => {
				this.loading = false
			})
		},
		callCloseEvent() {
			this.loading = true
			this.closeEvent(this.event.id).then(() => {
				this.loading = false
			})
		},
		callOpenEvent() {
			this.loading = true
			this.openEvent(this.event.id).then(() => {
				this.loading = false
			})
		},
		...mapActions('events', [
			'verifyEvent',
			'approveChange',
			'refuseChange',
			'cancelChange',
			'finishEvent',
			'activateEvent',
			'cancelEvent',
			'closeEvent',
			'openEvent'
		])
	}
}
</script>
